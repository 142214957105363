<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <div class="verify_logo">
        <img
          src="@/assets/images/logo/new_logo.png"
          width="160px"
          alt="Revivify"
        />
      </div>
      <b-col class="banner_image_verify">
        <!-- <img src="@/assets/images/banner/revivify-10.png" alt="Revivify" /> -->
        <div class="banner_content_verify">
          You will be redirected to login page. If you are not redirected
          automatically click
          <a class="seller_link" target="_blank" href="www.revivify.com/sell"
            >here</a
          >.
        </div>
      </b-col>
      <!-- <b-col lg="12" md="12" sm="12" class="d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <h4>Account Verification!</h4>
          <hr />
          <h5>{{ response_message }}</h5>
        </div>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BAlert,
} from "bootstrap-vue";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AuthServices from "@/apiServices/AuthServices";

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BAlert,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      response_message: null,
    };
  },
  computed: {},
  beforeMount() {
    this.loadDefault();
  },
  methods: {
    loadDefault() {
      const { token } = this.$route.query;
      if (token === undefined) {
        console.log("TOKEN_NOT_FOUND");
      } else {
        console.log("TOKEN_FOUND: ", token);
        this.verifyAccount(token);
      }
    },

    verifyAccount(tokenVal) {
      const sendData = { token: tokenVal };
      AuthServices.VerifyAccount(sendData)
        .then((response) => {
          this.response_message = response.data.message;
          if (response.data.status) {
            this.toastNotificatoin(
              response.data.message,
              "UserIcon",
              "success"
            );
            setTimeout(function () {
              window.location.replace("/sell/onboarding");
            }, 3000);
          } else {
            this.toastNotificatoin(response.data.message, "UserIcon", "error");
          }
          console.log("ACOUNT VERIFICATION: ", response.data);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },

    toastNotificatoin(message, icon, varient) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: icon,
          variant: varient,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.banner_image_verify img {
  width: 60%;
  height: 90vh;
}
.banner_image_verify {
  text-align: center;
  background: url("../../../assets/images/banner/revivify-10.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.verify_logo {
  position: absolute;
  top: 2rem;
  left: 2rem;
}
.banner_content_verify {
  position: absolute;
  width: 95%;
  bottom: 3rem;
  font-size: 1.4rem;
}
.seller_link {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .verify_logo {
    left: 0;
    text-align: center;
    width: 99%;
  }
  .banner_content_verify {
    padding: 0 3rem;
  }
}
</style>
